<template>
    <div class="Lottery">
        <!-- 樂透選單 --->
        <div class="menu">
          <div class="item" v-if='currentLotteryName'>
                {{this._lotteryConfig[this.currentLotteryName].name}}
            </div> 
            <div class="item">
                <date-picker  v-if="this.dailyInfo.includes(this.currentLotteryName)" type="year" v-model="timeDaily" value-type="date" default-panel="year" format="YYYY" @change="updateVal"></date-picker>
                <date-picker  v-else type="date" v-model="time" value-type="date"  format="YYYY-MM-DD" @change="updateVal"></date-picker>
            </div>
        </div>       
        <!-- 樂透類型 --->
        <LotteryInnerCard
            v-if="currentLotteryName"
            :lotteryCode="currentLotteryName" 
            :imgUrl="imgUrl" :lotteryType="`${this._lotteryConfig[this.currentLotteryName].lotteryType}`"
            @openVedio="openVedio"
        />
        <div class="lottoy_menu_wraper">
            <div class="lottoy_menu">
                <div class="menu_btn"
                    :class="{'active':key===currentTab}"
                    v-for="(item, key) in primaryMenu" 
                    :key="key"
                    @click="changeTab(key)">
                    <div>{{item.name}}</div> 
                    <!-- <div class="line"></div> -->
                </div>
            </div>
        </div>
        <!-- 顯示結果 -->
        <div class="result">
            <div class="result_menu"></div>
            <div class="result_display">
                <template v-if="currentTab==='history'">
                    <History :date="`${this.selectedYear}`" v-if="currentTab==='history' && (this.dailyInfo.includes(currentLotteryName))" ref="history"  @openDrawingVideo="openDrawingVideo" />
                    <History :date="`${this.selectedDate}`" v-else-if="currentTab==='history'" ref="history"  @openDrawingVideo="openDrawingVideo" />
                </template>
                <template v-else>
                    <ImagesLib :currentTab="currentTab"/>
                </template>
            </div>  
        </div>
        <div v-if="showVedio">
            <h5VideoLive @closeVedio="closeVedio" :lotteryType="clickVedioLotteryType" :lotteryInfo="clickVedioLotteryinfo" />
        </div>
      <div v-if="showDrawingVideo">
        <DrawingVideo @closeDrawingVideo="closeDrawingVideo" :year='year' :month='month' :date='date' :periodNo='periodNo' :drawNumber='drawNumber' :showAnimal='showAnimal' :showFiveElements='showFiveElements' />
      </div>
    </div>
</template>
<script>
import LotteryInnerCard from "@/components/H5/lottery_inner_card/Lottery_Inner_Card";
import History from "@/components/H5/lottery_result/History_Index";
import ImagesLib from "@/components/H5/lottery_result/Images_Lib";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import h5VideoLive from "@/components/live_stream/h5VideoLive";
import DrawingVideo from "@/components/live_stream/DrawingVideo";
export default {
    name:"Lottery",
    components:{
        LotteryInnerCard,
        History,
        DatePicker,
        ImagesLib,
        h5VideoLive,
      DrawingVideo,
    },
    data(){
        return{
            imgUrl:'',
            currentLotteryName:'',
            primaryMenu:{},
            secondaryMenu:[],
            currentTab:"history", 
            secondaryMenuOptionIndex:0, 
            selectedDate: moment(new Date()).format('YYYY-MM-DD'),
            selectedYear: moment(new Date()).format('YYYY'),
            timeDaily: new Date(),
            time: new Date(),
            lastClickTimeDaily: new Date(),
            dailyInfo: ['lucky7daily', 'pl5daily', 'qxc', 'pl3', 'pl5', 'xglhc', 'kl8lhc'],
            showVedio: false,
          showDrawingVideo: false,
            clickVedioLotteryType:'',
            clickVedioLotteryinfo:'',
          year:'',
          month:'',
          date:'',
          periodNo:'',
          drawNumber: [],
          showAnimal: [],
          showFiveElements: []
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
              this.reflashLotteryCard();
              if(to.query.currentTab){
                this.changeTab(to.query.currentTab)
              }
            },
            immediate: true
        } 
    },
    methods:{
        async init(){
            if(!this._lotteryConfig[this.currentLotteryName]){
               alert("無此彩種")
            }
            // 獲取當前彩種圖片url
            this.imgUrl = this._lotteryConfig[this.currentLotteryName].imgUrl;
            this.getPrimaryMenu()
            this.getSecondaryMenu(this.currentTab)   
        },
        // 獲取主選單
        getPrimaryMenu(){
            this.primaryMenu = this._lotteryConfig[this.currentLotteryName].mainMenu;
        },
        // 獲取子選單
        getSecondaryMenu(tabName){
            //當有子選當的返回到沒有子選單的彩種, 切換為開獎歷史
            if(this._lotteryConfig[this.currentLotteryName].mainMenu[tabName] === undefined){
                this.currentTab = 'history'
            } else {
                this.secondaryMenu = this._lotteryConfig[this.currentLotteryName].mainMenu[tabName].subMenu;
            }
        },
        // 切換主選單
        changeTab(tabName){
            if(tabName !=='history' && tabName !=='imageLib') return

            // 只要切換主選單，全部從置
            if(this.currentTab !== tabName){
                this.secondaryMenuOptionIndex = 0;
            }
            this.currentTab = tabName;
            this.getSecondaryMenu(tabName)
        },
        // 切換子選單
        changeSubMenu(index){
            this.secondaryMenuOptionIndex = index;
        },
        reflashLotteryCard() {
            this.currentLotteryName = this.$route.params.name.split('?')[0];
            this.init();
        },
        updateVal(e){
            //在圖紙圖庫頁面, 上方年份選擇不生效
            if(this.currentTab !== 'history') {
                this.timeDaily= this.lastClickTimeDaily;
                return false;
            }
            let _mc, _mc2;
            this.lastClickTimeDaily = e; //紀錄切換前的值
            if(this.dailyInfo.includes(this.currentLotteryName)){
                _mc= moment(e).format('YYYY');
                this.selectedYear= _mc;
                this.$refs.history.clickPage(this.selectedYear);
            } else {
                _mc= moment(e).format('YYYY-MM-DD')
                this.selectedDate= _mc;
                this.$refs.history.clickPage(this.selectedDate);
            }
        },
        openVedio(lotteryCode, lotteryInfo) {
            if(lotteryCode == undefined || lotteryInfo == undefined || lotteryInfo.next_data == undefined){
                return false;
            } else {
                this.clickVedioLotteryType = lotteryCode;
                this.clickVedioLotteryinfo = lotteryInfo;
                this.showVedio = true;
            }
        },
        closeVedio() {
            this.showVedio = false;
        },
      openDrawingVideo({year, month, date, periodNo, drawNumber, showAnimal, showFiveElements}){
          console.log('open')
        this.year = year;
        this.month = month;
        this.date = date;
        this.periodNo = periodNo;
        this.drawNumber = drawNumber;
        this.showAnimal = showAnimal;
        this.showFiveElements = showFiveElements;
        this.showDrawingVideo = true;
      },
      closeDrawingVideo(){
        this.showDrawingVideo = false;
      },
    },
    mounted(){
        this.reflashLotteryCard();
    },
    computed:{
         years () {
            const year = new Date().getFullYear()
            
            return Array.from({length: year - 1900}, (value, index) => 1901 + index).reverse()
        }
    }
    
}
</script>
<style lang="scss" scoped>
.Lottery{
    padding-top: 50px;
    padding-bottom: 62px;
    .menu{
        display: flex;
        background-color: #fff;
        .item{
            flex: 1;
            height: 44px;
            line-height: 44px;
            border-bottom: 1px solid #ccc;
            color:#727272;
            font-size:14px;
            font-weight:bold;
        }
        .item:last-child{
            border-left: 1px solid #ccc;
        }

    }

}



// lottoy_menu
.lottoy_menu_wraper{
    background-color: #fff;
    border-bottom: 4px solid #f6f3ec;
    border-top: 4px solid #f6f3ec;
    box-sizing: border-box;
    height: 34px;
    line-height: 30px;
    position: relative;
    .lottoy_menu{
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
        .menu_btn{
            color: #c4c4c4;
            font-size: 12px;
            font-weight: 700;
            height: 25px;
            padding: 0 10px;
            &.active{
                color: #0f2957;
                border-bottom: 2px solid #0f2957;
            } 
            .line{
                width: 1px;
                height: 14px;
                background: #ccc;
                margin: 0 .16rem;
            }
        }        
    }
   
    
}
       


// lottoy_submenu
.lottoy_subMenu{
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #dadada;
    background: #fff;
    font-size: 0.18rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    // justify-content:space-between;
    padding: 0.2rem 0.3rem;
   
    .subMenu{
        display: inline-block;
        color: #2e2e2e;
        padding: 0.1rem;

    }
    .active{
        color: coral;
        border: 1px solid coral;
    }
}



</style>
