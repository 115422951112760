<template>
    <div class="lhcBall">
      
        <div class="drawballs">
            <div :class="`${lotteryCode} ball ball-${parseInt(item)}`" v-for="(item,index) in lastDraw.slice(0, 6)" :key="index">{{item | padLeft(2)}}</div>
            <div class="add">+</div>
            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>  
        </div>


        <div class="mappingBall_sx" v-if="currentInfo && currentInfo.analysis != undefined">
           <div v-for="(item,index) in currentInfo.analysis.slice(0,6)" :key="index" class="item">
                {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{currentInfo.analysis[6] }}</div>
        </div>
             
        <div class="mappingBall_wx" v-if="currentInfo && currentInfo.analysis != undefined">
            <div v-for="(item,index) in currentInfo.analysis.slice(7,13)" :key="index" class="item">
                {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{currentInfo.analysis[13] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        lastDraw:Array | [],
        currentInfo:null   
    },
}
</script>
<style lang="scss" scoped>
.lhcBall{
    display: inline-grid;
}
.drawballs{
    display:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    margin-bottom: 5px;
    .ball{
        
        color: #fff;
        font-size: .17rem;
        @include ballSize(.3rem);
        &:nth-child(6){
            margin-right: 0;
        }
    }     
}
.mappingBall_sx,
.mappingBall_wx{
    display:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    margin-bottom: 5px;
    .item{
        line-height: .14rem;
        font-size: .12rem;
        color: #646363;   
       
    } 
}
.mappingBall_wx .item{
    color: #ff0000;
}

.add{
    font-size: 0.2rem;
    color: #646363;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>