<template>
    <div>
        <template v-if="/^(xglhc|lucky7daily)/.test(name) && dataList.length === 0">
            <div class="notify">还未到开奖时间</div>
        </template>
        <template v-else>
            <div class="child_menu">
                <div :class="`item ${selectItem==1 ? 'active' : ''}`" @click="selectItem=1">号码</div>
                <div :class="`item ${selectItem==2 ? 'active' : ''}`" @click="selectItem=2">总和</div>
                <div :class="`item ${selectItem==3 ? 'active' : ''}`" @click="selectItem=3">特码</div>
            </div>
            <table>
                <tr  v-if="selectItem==1">
                    <td >期数</td>
                    <td class="change_Type" v-if="selectItem==1">
                        <span 
                            class="btn zodiac" 
                            :class ="{'active':isShowSx}"
                            @click="changeType('sx')">
                            显示生肖
                        </span>
                        <span 
                            class="btn fivElements"
                            :class ="{'active':!isShowSx}"
                            @click="changeType('wx')">
                            显示五行
                        </span>
                    </td>
                </tr>
                <tr v-if="selectItem==2" >
                    <td class="border-right">期数</td>
                    <td class="border-right">总分</td>
                    <td class="border-right">总数单双</td>
                    <td class="border-right">总数大小</td>
                    <td class="border-right">七色波</td>
                    <td class="border-right">一肖量</td>
                    <td>尾数量</td>
                
                </tr>
                <tr v-if="selectItem==3">
                    <td class="border-right">期数</td>

                    <td class="border-right">单双</td>
                    <td class="border-right">大小</td>
                    <td class="border-right">合单双</td>
                    <td class="border-right">合大小</td>
                    <td class="border-right">尾大小</td>
                    <td>五行</td> 
                </tr>

                <!--- 歷史結果列表 --->
                <tr v-for="(item,index) in dataList" :key="index" class="history_list">
                    <td class="time">{{item.period_no[0]}}期<div class="date"> {{item.draw_time[0] | formateDrawTimeDateMD}}</div></td>
                    <td v-if="selectItem==1">
                      <div style="display:flex;align-items:center;justify-content:center;">
                        <div class="displayType" :class="{ 'with-playback': name === 'kl8lhc' }">
                            <div class="ballWrapper">
                                <div :class="`${name} ball ball-${parseInt(num)}`" 
                                    v-for="(num,index) in item.ShowNumber.slice(0,6)" 
                                    :key="index">{{num | padLeft(2)}}</div>
                                    <div class="add">+</div>
                                    <template v-if="name == 'lucky7daily'"><!--只針對英國六修正-->
                                        <div v-if="item.ShowNumber[6]" :class="`${name} ball ball-${parseInt(item.ShowNumber.slice(6,7))}`">{{parseInt(item.ShowNumber.slice(6,7)) | padLeft(2)}}</div>
                                        <div v-else></div>
                                    </template>
                                    <template v-else>
                                        <div :class="`${name} ball ball-${parseInt(item.ShowNumber.slice(6,7))}`">{{parseInt(item.ShowNumber.slice(6,7)) | padLeft(2)}}</div>  
                                    </template>
                            </div>
                            <div  v-if="item.ShowAnimal">
                                <div  v-show="isShowSx" class="textWrapper mappingBall_sx">

                                    <div v-for="(sx,index) in item.ShowAnimal.slice(0,6)"  class="text" :key="index+'-sx'">
                                        {{sx}}
                                    </div>
                                    <div class="add"></div>
                                    <div class="text">{{item.ShowAnimal[6]}}</div>
                                
                                </div>
                                <div  v-show="!isShowSx" class="textWrapper mappingBall_wx">
                                    <div v-for="(wx,index) in item.ShowFiveElements.slice(0,6)"  class="text" :key="index+'-wx'" >
                                        {{wx}}
                                    </div>
                                    <div class="add"></div>
                                    <div class="text">{{item.ShowFiveElements[6]}}</div>
                                </div>

                            </div>
                        </div>
                        <a @click.prevent='playDraw(item)' v-if="name === 'kl8lhc'" class='playback'>
                          <img src='@/img/icon_playback.png' />
                        </a>
                      </div>
                    </td>
                    <td v-if="selectItem==null" class="border-left border-right" align="center" >
                        <div class="item_wrapper">
                            <template v-if="name == 'lucky7daily'"><!--只針對英國六修正-->
                                <div v-if="item.ShowNumber[6]" :class="`${name} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                            </template>
                            <template v-else>
                                <div :class="`${name} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                            </template>

                            <div class="text" v-if="item.ShowAnimal" >
                                {{item.ShowAnimal[6]}}
                            </div>
                        </div>
                    </td> 
                    <td v-if="selectItem==2" class="border-right total-score">{{item.TotalScore[0]}}</td>
                    <td v-if="selectItem==2" class="border-right"><span :class="getUseColor(item.TotalSingleDouble[0])">{{item.TotalSingleDouble[0]}}</span></td>
                    <td v-if="selectItem==2" class="border-right"><span :class="getUseColor(item.TotalBigSmall[0])">{{item.TotalBigSmall[0]}}</span></td>
                    <td v-if="selectItem==2" class="border-right" :style="{'color':`${getUseColorcClour(item.SevenColor[0])}`}">
                        {{item.SevenColor[0]}}
                    </td>
                    <td v-if="selectItem==2" class="border-right">{{item.AnimalCount[0]}}</td>
                    <td v-if="selectItem==2" >{{item.TailCount[0]}}</td>
                    <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSingleDouble[0])">{{item.SpecialSingleDouble[0]}}</span></td>
                    <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialBigSmall[0])">{{item.SpecialBigSmall[0]}}</span></td>
                    <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSumSingleDouble[0])">{{item.SpecialSumSingleDouble[0]}}</span></td> 
                    <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSumBigSmall[0])">{{item.SpecialSumBigSmall[0]}}</span></td>
                    <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialTailBigSmall[0])">{{item.SpecialTailBigSmall[0]}}</span></td> 
                    <td v-if="selectItem==3"  style="color:#0380d8">{{item.SpecialFiveElements[0]}}</td> 
                </tr>

                <template v-if="appendList.length > 0">
                    <tr v-for="(item,index) in appendList" :key="'append-'+index" class="history_list">
                        <td class="time">{{item.period_no[0]}}期<div class="date"> {{item.draw_time[0] | formateDrawTimeDateMD}}</div></td>
                        <td v-if="selectItem==1">
                            <div class="displayType" :class="{ 'with-playback': name === 'kl8lhc' }">
                                  <div class="ballWrapper">
                                      <div :class="`${name} ball ball-${parseInt(num)}`"
                                          v-for="(num,index) in item.ShowNumber.slice(0,6)"
                                          :key="index">{{num | padLeft(2)}}</div>
                                      <div class="add">+</div>
                                      <div :class="`${name} ball ball-${parseInt(item.ShowNumber.slice(6,7))}`">{{parseInt(item.ShowNumber.slice(6,7)) | padLeft(2)}}</div>
                                  </div>
                                  <div  v-if="item.ShowAnimal">
                                      <div  v-show="isShowSx" class="textWrapper mappingBall_sx">
                                          <div v-for="(sx,index) in item.ShowAnimal.slice(0,6)"  class="text" :key="index+'-sx'">
                                              {{sx}}
                                          </div>
                                          <div class="add"></div>
                                          <div class="text">{{item.ShowAnimal[6]}}</div>
                                      </div>
                                      <div v-show="!isShowSx" class="textWrapper mappingBall_wx">
                                          <div v-for="(wx,index) in item.ShowFiveElements.slice(0,6)"  class="text" :key="index+'-wx'" >
                                              {{wx}}
                                          </div>
                                          <div class="add"></div>
                                          <div class="text">{{item.ShowFiveElements[6]}}</div>
                                      </div>
                                  </div>
                              </div>
                        </td>
                        <td v-if="selectItem==null" class="border-left border-right" align="center" >
                            <div class="item_wrapper">
                                <div :class="`${name} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                                <div class="text" v-if="item.ShowAnimal" >
                                    {{item.ShowAnimal[6]}}
                                </div>
                            </div>
                        </td>
                        <td v-if="selectItem==2" class="border-right total-score">{{item.TotalScore[0]}}</td>
                        <td v-if="selectItem==2" class="border-right"><span :class="getUseColor(item.TotalSingleDouble[0])">{{item.TotalSingleDouble[0]}}</span></td>
                        <td v-if="selectItem==2" class="border-right"><span :class="getUseColor(item.TotalBigSmall[0])">{{item.TotalBigSmall[0]}}</span></td>
                        <td v-if="selectItem==2" class="border-right" :style="{'color':`${getUseColorcClour(item.SevenColor[0])}`}">
                            {{item.SevenColor[0]}}
                        </td>
                        <td v-if="selectItem==2" class="border-right">{{item.AnimalCount[0]}}</td>
                        <td v-if="selectItem==2" >{{item.TailCount[0]}}</td>
                        <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSingleDouble[0])">{{item.SpecialSingleDouble[0]}}</span></td>
                        <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialBigSmall[0])">{{item.SpecialBigSmall[0]}}</span></td>
                        <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSumSingleDouble[0])">{{item.SpecialSumSingleDouble[0]}}</span></td>
                        <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialSumBigSmall[0])">{{item.SpecialSumBigSmall[0]}}</span></td>
                        <td v-if="selectItem==3" class="border-right"><span :class="getUseColor(item.SpecialTailBigSmall[0])">{{item.SpecialTailBigSmall[0]}}</span></td>
                        <td v-if="selectItem==3"  style="color:#0380d8">{{item.SpecialFiveElements[0]}}</td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>

<script>
export default {
    props:{
        dataList:Array,
        appendList:Array,
        name:String,
        lotteryType:Number, //1低頻 2高頻,
        selectedDate:String
    },
    data(){
        return{
            isShowSx:true,  
            selectItem:1, //1:號碼 2:總和 3:特碼
        }
    },
     watch: {
        '$route': {
            handler: function(to, from) {

            },
            immediate: true
        } 
    },
    computed: {
        isDraw() {
          return [2,4,6].includes(new Date(this.selectedDate).getDay())
        }
    },
    methods:{
        changeType(type){
            if(type == 'sx'){
                this.isShowSx = true;
            }else{
                this.isShowSx = false;
            }
        },
        getSpecialNum(string){
            return string.split(',').pop();
        },
        getUseColor(string){
            if( string === '单' || string === '小'){
                return 'color-blue'
            }
            return 'color-red'
        },
        getUseColorcClour(string){
            switch(string)
            {
                case '红波':
                    return 'red';
                case '绿波':
                    return '#23b439';
                case '蓝波':
                    return '#0380d8';
                case '和局':
                    return '#646363';
                default:
                    return 'red'
            }
        },
      playDraw(item){
        const [ year, month, date ] = item.draw_time.join().split(' ')[0].split('/')
        const periodNo = item.period_no[0];
        const drawNumber = item.ShowNumber;
        const showAnimal = item.ShowAnimal;
        const showFiveElements = item.ShowFiveElements;
        this.$emit('openDrawingVideo', {year, month, date, periodNo, drawNumber, showAnimal, showFiveElements});
      }

    },
   
}

</script>
<style lang="scss" scoped>
.notify{
  margin-top:5vh;
  color:#c4c4c4;
  letter-spacing: 3px;
  font-size:32px;
}
.child_menu{
    display: flex;
    background-color: #fff;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    height:32px;
    .item{
        flex:1;
        color:#727272;
        font-size:12px;
        font-weight:bold;
        margin: 0 20px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        &.active{
            color: #0f2957;
            border-bottom: 2px solid #0f2957;
        }
    }
}
/** 歷史列表title
******************************/
table{
    /*margin-bottom:.80rem ;*/
    font-weight: 600;
    font-size: 12px;
    background: #fff;
}
tr:nth-child(1){
    font-weight:bold;
    background:#e3e3e3;
    color:#727272;
    height:36px;
    border-bottom: 0;
} 
tr{
    border-bottom: 1px solid #e3e3e3;
    td:first-child{
        border-right:1px solid #e3e3e3;
    }
}

tr:nth-child(2){
    background: #fff;
    color: #0f2957#b2b2b2;
    height: 54px;
    font-weight: bolder;
    
    td{

       // border: 1px solid #e0d6a7;
    }
}
tr:nth-child(1){
    .change_Type{
        .btn{
            cursor: pointer;
            display: inline-block;
            color: #727272;
            &.active{
                color: #fff;
                border-radius: 12px;
                background-color: #0f2957;
                padding: 8px;         
            }
        }
        .btn:nth-child(2){
            margin: 0 15px;
        }
    }
}
/** 歷史列表
******************************/
.history_list{
    height: 54px;
    font-size: 12px;
    color:#646363;
    .time{
        .date{
            margin-top: .05rem;
        }
    }
    .displayType{
        padding: 10px;
        display: flex;
        flex-direction: column;
      width:100%;

      &.with-playback{
        width:calc(100% - 40px);
        margin-left:0;
        margin-right:auto;
      }
        .ballWrapper{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .ball{
                @include ballSize(0.22rem);   
                margin-right: 2px;
                font-size:.12rem; 
            }
           
        }
        .textWrapper{
            margin-top: .05rem;
            display: flex;
            justify-content: space-around;
             &.mappingBall_wx{
                .text{
                    color: #ff0000;
                }
            }
        }
    }
}

.item_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    .ball{
        line-height: 24px;
        color: #fff;
    }
    .text{
        margin-top: .05rem;
    } 
   
}


.border-left{
    border-left:1px solid #e3e3e3 ;
}
.border-right{
    border-right:1px solid #e3e3e3 ;
}
.ball{margin:0 5px;}
.playback{
  background:#6C5D00;
  color:#fff;
  padding:5px 2px 5px 4px;
  border-radius:15px 0 0 15px;
  display:flex;
  align-items:center;
  width:38px;
  position:absolute;
  right:0;
  font-size:12px;
  margin-left:auto;
  margin-right:0;
  cursor:pointer;
  img{margin-right:1px;}
}
</style>
