<template>
    <div> 
        <template v-if="dataList.length === 0">
            <div class="notify">还未到开奖时间</div>
        </template>
        <template v-else>
            <div class="child_menu">
                <div :class="`item ${selectItem==1 ? 'active' : ''}`" @click="selectItem=1">号码</div>
                <div :class="`item ${selectItem==2 ? 'active' : ''}`" @click="selectItem=2">总和</div>
            </div>
            <table>
                <tr class="header__title">
                    <td class="period">期数</td>
                    <td class="drawNum" v-if="selectItem==1">开奖号码</td>
                    <td class="result" v-if="selectItem==2">总和</td>
                </tr>
                <!--- 歷史結果列表 --->
                <tr v-for="(item,index) in dataList" :key="index" class="history__item">
                    <td>
                        <p class="period_txt">{{item.period_no[0]}}期</p>
                        <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
                    </td>
                
                    <td v-if="selectItem==1">
                        <div class="ball__wrapper"> 
                            <div :class="`${name} ball ball-${num} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div> 
                            <!-- <div class="ball"  v-for="(num,index) in item.draw.split(',')" :key="index">{{num}}</div>  -->
                        </div>
                    </td>
                    <td v-if="selectItem==2">
                        <div class="sum__wrapper">
                            <div class="text">{{item.TotalSum[0]}}</div>
                            <div class="text" :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</div>
                        </div>
                    </td>
                </tr>
                <template v-if="appendList.length > 0">
                    <tr v-for="(item,index) in appendList" :key="'append-'+index" class="history__item">
                        <td>
                            <p class="period_txt">{{item.period_no[0]}}期</p>
                            <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
                        </td>
                        <td v-if="selectItem==1">
                            <div class="ball__wrapper">
                                <div :class="`${name} ball ball-${num} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div>
                                <!-- <div class="ball"  v-for="(num,index) in item.draw.split(',')" :key="index">{{num}}</div>  -->
                            </div>
                        </td>
                        <td v-if="selectItem==2">
                            <div class="sum__wrapper">
                                <div class="text">{{item.TotalSum[0]}}</div>
                                <div class="text" :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</div>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        appendList:Array,
        name:String,
        selectedDate:String
    },
     watch: {
        '$route': {
            handler: function(to, from) {

            },
            immediate: true
        } 
    },
    methods:{
        getUseColor(string){
            if( string === '单' || string === '小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    },
     data(){
        return{
            isShowSx:true,  
            selectItem:1, //1:號碼 2:總和 3:特碼
        }
    },
    computed: {
        isDraw() {
          return [2,5,0].includes(new Date(this.selectedDate).getDay())
        }
    },
    created(){
        this.selectItem=1;
    }
}

</script>
<style lang="scss" scoped>
.notify{
  margin-top:5vh;
  color:#c4c4c4;
  letter-spacing: 3px;
  font-size:32px;
}
.child_menu{
    display: flex;
    background-color: #fff;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    height:30px;
    .item{
        flex:1;
        color:#727272;
        font-size:12px;
        font-weight:bold;
        margin: 0 20px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        &.active{
            color: #0f2957;
            border-bottom: 2px solid #0f2957;
        }
    }
}
table{
    background: #fff;
    font-size: 12px;
}
tr:nth-child(1){
    font-weight:bold;
    background:#e3e3e3;
    color:#727272;
    height:36px;
    border-bottom: 0;
} 
tr{
    border-bottom: 1px solid #e3e3e3;
}
.header__title{
    background: #fff;
    height: 36px;
    font-weight: bold;
    font-size: 12px;
    .time{
        width: 2.15rem;
    }
    .period{
        width: 2.15rem;
    }
    .drawNum{
        width: 5.67rem;
    }
    .result{
        width: 4.46rem;
    }
}


/** 歷史列表
******************************/
.history__item{
    height: 54px;
    font-size: 12px;
    color: #646363;
    .period_txt{
        font-size: 12px;
        margin-bottom: 5px;
        color: #727272;
    }
    .date_txt{
        font-size: 12px;
        color: #999;
    }

    .ball__wrapper{
        display: flex;
        justify-content: center;
        .ball{
            @include ballSize(0.22rem);     
            margin-right: 2px;
            font-size:.12rem;
            color: #fff;
        }
    }
    .sum__wrapper{
        display: flex;
        .text{
           
            width: 50%;
            &:last-child{
                font-weight: 600;
            }
        }
        
    }  
}



</style>
