<template>
    <div class="content">
        <template v-if="dataList.length === 0">
            <div class="notify">还未到开奖时间</div>
        </template>
        <template v-else>
            <div class="child_menu">
                <div :class="`item ${selectItem==1 ? 'active' : ''}`" @click="selectItem=1">号码</div>
                <div :class="`item ${selectItem==2 ? 'active' : ''}`" @click="selectItem=2">总和</div>
            </div>
            <table>
                <!--- 歷史結果標題 --->
                <tr class="header-title">
                    <td class="period">期数</td>
                    <td v-if="selectItem==1" class="result">开奖号码</td>
                    <td v-if="selectItem==2" class="sum">总和</td>
                </tr>
                <!--- 歷史結果列表 --->
                <tr v-for="(item,index) in dataList" :key="index" class="history_item">
                    <td>
                        <p class="period_txt">{{item.period_no[0]}}期</p>
                        <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
                    </td>
                    <td v-if="selectItem==1">
                        <div class="ball-wrapper"> 
                            <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div> 
                        </div>
                    </td>
                    <td v-if="selectItem==2" class="total sum_result">
                        <span>{{item.TotalSum[0]}}</span>
                        <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
                    </td>
                </tr>
                <template v-if="appendList.length > 0">
                    <tr v-for="(item,index) in appendList" :key="'append-'+index" class="history_item">
                        <td>
                            <p class="period_txt">{{item.period_no[0]}}期</p>
                            <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
                        </td>
                        <td v-if="selectItem==1">
                            <div class="ball-wrapper">
                                <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div>
                            </div>
                        </td>
                        <td v-if="selectItem==2" class="total sum_result">
                            <span>{{item.TotalSum[0]}}</span>
                            <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
                        </td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        appendList:Array,
        name:String
    },
     watch: {
        '$route': {
            handler: function(to, from) {
            },
            immediate: true
        } 
    },
    methods:{
        getUseColor(string){
            if( string === '单' || string === '小' || string === '尾小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    },
    data(){
        return{
            selectItem:1, //1:號碼 2:總和 3:特碼
        }
    },
}
</script>
<style lang="scss" scoped>
.notify{
  margin-top:5vh;
  color:#c4c4c4;
  letter-spacing: 3px;
  font-size:32px;
}
.content{
   .child_menu{
        display: flex;
        background-color: #fff;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        height:32px;
        .item{
            flex:1;
            color:#727272;
            font-size:12px;
            font-weight:bold;
            margin: 0 20px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            &.active{
                color: #0f2957;
                border-bottom: 2px solid #0f2957;
            }
        }
    }
    /*** 歷史列表 table ***/
    table{
        background: #fff;
         font-size: 12px;
         tr:nth-child(1){
            font-weight:bold;
            background:#e3e3e3;
            color:#727272;
            height:36px;
            border-bottom: 0;
        } 
        tr{
            border-bottom: 1px solid #e3e3e3;
        }
        /*** 歷史列表 title ***/
        .header-title{
            font-weight: 700;
            background: #fff;
            color: #0f2957;
            height: 36px;
            font-size:12px;
            td{
                border: 1px solid #e3e3e3;
            }
            .time,.period{
                width: 2.15rem;
            }
            .result{
                width: 5.67rem;
            }
            .sum{
                width: 4.46rem;
            }
        }
        /*** 歷史列表 item ***/
        .history_item{
            height: 54px;
            font-size: 12px;
            color: #646363;
            border: 1px solid #e3e3e3;
            .ball-wrapper{
                display: flex;
                justify-content: center;
                .ball{
                    @include ballSize(0.22rem);     
                    margin-right: 2px;
                    font-size:.12rem;
                }
            }
            .sum_result{
                span{
                    font-weight: 600;
                    width: 50%;
                    display: inline-block;
                }
            }  
        }
    }  
}


</style>
