<template>
  <div class="content">
    <template v-if="dataList.length === 0">
                <div class="notify">暂无资料</div>
    </template>
    <template v-else>
    <table>
      <!--- 歷史結果標題 --->
      <tr class="header-title">
        <td class="period">期数</td>
        <td class="result">
          <span
            class="btn"
            :class="{ active: resultType == '0' }"
            @click="resultType = 0"
          >
            号码
          </span>
           <span v-if="dataList[0] && dataList[0].ShowBigSmall"
            class="btn"
            :class="{ active: resultType == '1' }"
            @click="resultType = 1"
          >
            大小
          </span>
           <span v-if="dataList[0] && dataList[0].ShowSingleDouble"
            class="btn"
            :class="{ active: resultType == '2' }"
            @click="resultType = 2"
          >
            单双
          </span>
           <span v-if="dataList[0] && dataList[0].ShowParallel"
            class="btn"
            :class="{ active: resultType == '3' }"
            @click="resultType = 3"
          >
            对子
          </span>

           <span v-if="dataList[0] && dataList[0].TopTwiceSum"
            class="btn"
            :class="{ active: resultType == '4' }"
            @click="resultType = 4"
          >
            冠亚军和
          </span>
          <span v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger"
            class="btn"
            :class="{ active: resultType == '5' }"
            @click="resultType = 5"
          >
            1~5龙虎
          </span>
           <span v-if="dataList[0] && dataList[0].TotalSum && dataList[0].DragonTiger"
            class="btn"
            :class="{ active: resultType == '6' }"
            @click="resultType = 6"
          >
            总和/龙虎
          </span>
          <span v-if="dataList[0] && dataList[0].TotalSum && dataList[0].TailBigSmall"
            class="btn"
            :class="{ active: resultType == '7' }"
            @click="resultType = 7"
          >
            总和/尾大小
          </span>
           <span v-if="dataList[0] && dataList[0].TotalSum && !dataList[0].DragonTiger && !dataList[0].TailBigSmall"
            class="btn"
            :class="{ active: resultType == '12' }"
            @click="resultType = 12"
          >
            总和
          </span>
        
          <span v-if="dataList[0] && dataList[0].OneAndFourDragonTiger"
            class="btn"
            :class="{ active: resultType == '8' }"
            @click="resultType = 8"
          >
            1~4龙虎
          </span>
          <span v-if="dataList[0] && dataList[0].SingleDoubleTie && dataList[0].MultiMarkup && dataList[0].FiveElements"
            class="btn"
            :class="{ active: resultType == '9' }"
            @click="resultType = 9"
          >
            形态
          </span>

          <span v-if="dataList[0]  && dataList[0].TopThree && dataList[0].MiddleThree && dataList[0].LastThree"
            class="btn"
            :class="{ active: resultType == '10' }"
            @click="resultType = 10"
          >
            形态
          </span>
         

          <span v-if="dataList[0] && dataList[0].FishShrimpAndCrab"
            class="btn"
            :class="{ active: resultType == '11' }"
            @click="resultType = 11"
          >
            鱼蟹虾
          </span>
          
        </td>
        
      </tr>
      <!--- 歷史結果列表 --->
      <tr
        v-for="(item, index) in dataList"
        :key="index"
        class="history_item"
        v-if="dataList.length > 0"
      >
        <td>
          <p class="period_txt">{{ item.period_no[0] }}期</p>
          <p v-if="lotteryType == 2" class="date_txt">
            {{ item.draw_time[0] | formateDrawTime }}
          </p>
          <p v-else class="date_txt">
            {{ item.draw_time[0] | formateDrawTimeDateMD }}
          </p>
        </td>
        <td>
          <div
            :class="
              `ball-wrapper`
            "
          >
            <!--  號碼  -->
            <div  v-if="resultType==0"  :class="
              `ball-wrapper ${
                LotteryTwenty.includes(name) ? 'twentyBall' : ''
              } `
            ">
                <div  :class="`${name} ball ball-${parseInt(num)} ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"
                    v-for="(num, index) in $options.filters.splitDrawNumber(item.ShowNumber,name)"
                    :key="index">
                    <span v-if="padLeftLottery.includes(name)">{{
                        num | padLeft(2)
                    }}</span>
                    <span v-else-if="padLeftLottery2.includes(name)">{{
                        parseInt(num)
                    }}</span>
                    <span v-else>
                        {{ num }}
                    </span>
                </div>
            </div> 
            <!--  大小  -->
            <div v-if="resultType==1" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                <div  v-for="(option,index) in item.ShowBigSmall" :key="index" :class="`ball ${styleFilter(option)}`" >
                    <span>{{option}}</span>
                </div>
            </div>
            <!--  單雙  -->
            <div v-if="resultType==2" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                <div  v-for="(option,index) in item.ShowSingleDouble" :key="index" :class="`ball ${styleFilter(option)}`" >
                    <span>{{option}}</span>
                </div>
            </div>
            <!-- 對子 -->

          <div v-if="resultType==3"  :class="
              `ball-wrapper ${
                LotteryTwenty.includes(name) ? 'twentyBall' : ''
              } `
            ">
                <div  :class="`box  ${item.ShowParallel.includes(num) ? `${name} ball  ball-${parseInt(num)} color-white` : 'color-black' } ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"
                    v-for="(num, index) in $options.filters.splitDrawNumber(item.ShowNumber,name)"
                    :key="index">
                    <span v-if="padLeftLottery.includes(name)">{{
                        num | padLeft(2)
                    }}</span>
                    <span v-else-if="padLeftLottery2.includes(name)">{{
                        parseInt(num)
                    }}</span>
                    <span v-else>
                        {{ num }}
                    </span>
                </div>
            </div> 
            <!--   -->
            <div class="type-detail" v-if="resultType==6" >
                <template v-if="dataList[0] && dataList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="dataList[0] && dataList[0].DragonTiger" >
                 <span  v-for="(option,index) in item.DragonTiger" :key="'DragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                    {{option}}
                </span>
                </template>
            </div>
            <div class="type-detail" v-if="resultType==7" >
               <template v-if="dataList[0] && dataList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              <template v-if="dataList[0] && dataList[0].TailBigSmall" >
                 <span  v-for="(option,index) in item.TailBigSmall" :key="'TailBigSmall-'+index" :class="`${$options.filters.drawsFilter(option.slice(1))}`" >
                    {{option.slice(1)}}
                </span>
                </template>
             </div>
             
              <div class="type-detail" v-if="resultType==8" >
                <template v-if="dataList[0] && dataList[0].OneAndFourDragonTiger" >
                    <span   v-for="(option,index) in item.OneAndFourDragonTiger" :key="'OneAndFourDragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==4" >
                <template v-if="dataList[0] && dataList[0].TopTwiceSum" >
                    <span   v-for="(option,index) in item.TopTwiceSum" :key="'TopTwiceSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==5" >
                <template v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger" >
                    <span   v-for="(option,index) in item.OneAndFiveDragonTiger" :key="'OneAndFiveDragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==11" >
                <template v-if="dataList[0] && dataList[0].FishShrimpAndCrab" >
                    <span   v-for="(option,index) in item.FishShrimpAndCrab" :key="'FishShrimpAndCrab-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==12" >
                <template v-if="dataList[0] && dataList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
               </div>
            
              <div class="type-detail" v-if="resultType==10" >

                
                <template v-if="dataList[0] && dataList[0].TopThree" >
                    <span   v-for="(option,index) in item.TopThree" :key="'TopThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                 <template v-if="dataList[0] && dataList[0].MiddleThree" >
                    <span   v-for="(option,index) in item.MiddleThree" :key="'MiddleThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="dataList[0] && dataList[0].LastThree" >
                    <span   v-for="(option,index) in item.LastThree" :key="'LastThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
            
              <div class="type-detail" v-if="resultType==9" >
                <template v-if="dataList[0] && dataList[0].SingleDoubleTie" >
                    <span   v-for="(option,index) in item.SingleDoubleTie" :key="'SingleDoubleTie-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="dataList[0] && dataList[0].FrontBackendTie" >
                    <span   v-for="(option,index) in item.FrontBackendTie" :key="'FrontBackendTie-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="dataList[0] && dataList[0].MultiMarkup" >
                    <span   v-for="(option,index) in item.MultiMarkup" :key="'MultiMarkup-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                 <template v-if="dataList[0] && dataList[0].FiveElements" >
                    <span   v-for="(option,index) in item.FiveElements" :key="'FiveElements-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
            
          </div>
        </td>
      </tr>
      <tr v-else>
        <td></td>
      </tr>


      <template v-if="appendList.length > 0">
        <tr
            v-for="(item, index) in appendList"
            :key="'append-'+index"
            class="history_item"

        >
          <td>
            <p class="period_txt" v-if="item.period_no">{{ item.period_no[0] }}期</p>
            <p v-if="lotteryType == 2" class="date_txt">
              {{ item.draw_time[0] | formateDrawTime }}
            </p>
            <p v-else class="date_txt">
              {{ item.draw_time[0] | formateDrawTimeDateMD }}
            </p>
          </td>
          <td>
            <div class="ball-wrapper">
              <!--  號碼  -->
              <div  v-if="resultType==0"  :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : '' } `">
                <div :class="`${name} ball ball-${parseInt(num)} ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"
                     v-for="(num, index) in $options.filters.splitDrawNumber(item.ShowNumber,name)"
                     :key="index">
                    <span v-if="padLeftLottery.includes(name)">{{
                        num | padLeft(2)
                      }}</span>
                  <span v-else-if="padLeftLottery2.includes(name)">{{
                      parseInt(num)
                    }}</span>
                  <span v-else>
                        {{ num }}
                    </span>
                </div>
              </div>
              <!--  大小  -->
              <div v-if="resultType==1" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                <div  v-for="(option,index) in item.ShowBigSmall" :key="index" :class="`ball ${styleFilter(option)}`" >
                  <span>{{option}}</span>
                </div>
              </div>
              <!--  單雙  -->
              <div v-if="resultType==2" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                <div  v-for="(option,index) in item.ShowSingleDouble" :key="index" :class="`ball ${styleFilter(option)}`" >
                  <span>{{option}}</span>
                </div>
              </div>
              <!-- 對子 -->

              <div v-if="resultType==3"  :class="
              `ball-wrapper ${
                LotteryTwenty.includes(name) ? 'twentyBall' : ''
              } `
            ">
                <div :class="`box  ${item.ShowParallel.includes(num) ? `${name}   ball-${parseInt(num)} color-white ball` : 'color-black' } ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"
                     v-for="(num, index) in $options.filters.splitDrawNumber(item.ShowNumber,name)"
                     :key="index">
                    <span v-if="padLeftLottery.includes(name)">{{
                        num | padLeft(2)
                      }}</span>
                  <span v-else-if="padLeftLottery2.includes(name)">{{
                      parseInt(num)
                    }}</span>
                  <span v-else>
                        {{ num }}
                    </span>
                </div>
              </div>
              <!--   -->
              <div class="type-detail" v-if="resultType==6" >
                <template v-if="appendList[0] && appendList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].DragonTiger" >
                 <span  v-for="(option,index) in item.DragonTiger" :key="'DragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                    {{option}}
                </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==7" >
                <template v-if="appendList[0] && appendList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].TailBigSmall" >
                 <span  v-for="(option,index) in item.TailBigSmall" :key="'TailBigSmall-'+index" :class="`${$options.filters.drawsFilter(option.slice(1))}`" >
                    {{option.slice(1)}}
                </span>
                </template>
              </div>

              <div class="type-detail" v-if="resultType==8" >
                <template v-if="appendList[0] && appendList[0].OneAndFourDragonTiger" >
                    <span   v-for="(option,index) in item.OneAndFourDragonTiger" :key="'OneAndFourDragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==4" >
                <template v-if="appendList[0] && appendList[0].TopTwiceSum" >
                    <span   v-for="(option,index) in item.TopTwiceSum" :key="'TopTwiceSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==5" >
                <template v-if="appendList[0] && appendList[0].OneAndFiveDragonTiger" >
                    <span   v-for="(option,index) in item.OneAndFiveDragonTiger" :key="'OneAndFiveDragonTiger-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
               <div class="type-detail" v-if="resultType==12" >
                <template v-if="appendList[0] && appendList[0].TotalSum" >
                    <span  v-for="(option,index) in item.TotalSum" :key="'TotalSum-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
               </div>
              <div class="type-detail" v-if="resultType==11" >
                <template v-if="appendList[0] && appendList[0].FishShrimpAndCrab" >
                    <span   v-for="(option,index) in item.FishShrimpAndCrab" :key="'FishShrimpAndCrab-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==10" > 
              
                <template v-if="appendList[0] && appendList[0].TopThree" >
                    <span   v-for="(option,index) in item.TopThree" :key="'TopThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].MiddleThree" >
                    <span   v-for="(option,index) in item.MiddleThree" :key="'MiddleThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].LastThree" >
                    <span   v-for="(option,index) in item.LastThree" :key="'LastThree-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
              <div class="type-detail" v-if="resultType==9" >
                <template v-if="appendList[0] && appendList[0].SingleDoubleTie" >
                    <span   v-for="(option,index) in item.SingleDoubleTie" :key="'SingleDoubleTie-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].FrontBackendTie" >
                    <span   v-for="(option,index) in item.FrontBackendTie" :key="'FrontBackendTie-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].MultiMarkup" >
                    <span   v-for="(option,index) in item.MultiMarkup" :key="'MultiMarkup-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
                <template v-if="appendList[0] && appendList[0].FiveElements" >
                    <span   v-for="(option,index) in item.FiveElements" :key="'FiveElements-'+index" :class="`${$options.filters.drawsFilter(option)}`" >
                        {{option}}
                    </span>
                </template>
              </div>
            </div>
          </td>
        </tr>
      </template>

    </table>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    dataList: Array,
    appendList: Array,
    name: String,
  },
  data() {
    return {
      padLeftLottery: [
        "azxy8",
        "jisukuaile",
        "lucky7daily",
        "infinity8",
        "infinity8ex",
        "cannon20",
        "cannon20ex",
        "lucky5",
        "lucky5ex",
        "xglhc",
      ],
      LotteryTwenty: ["cannon20", "azxy20", "cannon20ex"],
      padLeftLottery2: ["azxy10", "azxy20", "jisusaiche"],
      lotteryType: 0,
      resultType: 0, //預設顯示號碼
    };
  },
  watch: {
        '$route': {
            handler: function(to, from) {
              this.resultType=0;
            },
            immediate: true
        } 
  },
  methods:{
         styleFilter: function (value) {
            if(value=='单' || value == '小'){
                return 'lightblue';
            }else if(value=='双' || value == '大'){
                return 'blue';
            }else{
                 return 'yellow';
            }
        }
    },
  mounted() {
    this.lotteryType = this._lotteryConfig[this.name].lotteryType;
  },
};
</script>
<style lang="scss" scoped>
.notify{
  margin-top:5vh;
  color:#c4c4c4;
  letter-spacing: 3px;
  font-size:32px;
}
.content {
  margin-bottom: 0.8rem;
  /*** 歷史列表 table ***/
  table {
    background: #fff;
    font-size: 12px;
    /*** 歷史列表 title ***/
    .header-title {
      align-items: center;
      font-weight: 700;
      background: #fff;
      height: 36px;
      font-size: 12px;
      color: #727272;
      border-bottom: 4px solid #f6f3ec;
      .result{
        width: 284px;
        display: inline-block;
        overflow-x: auto;
        white-space: nowrap;
      }
      .time,
      .period {
        width: 26%;
      }
      .sum {
        width: 4.46rem;
      }
    }
    /*** 歷史列表 item ***/
    .history_item {
      height: auto;
      font-size: 0.17rem;
      color: #646363;
      border-bottom: 1px solid #e3e3e3;
      .period_txt {
        font-size: 12px;
        margin-bottom: 5px;
        color: #727272;
      }
      .date_txt {
        font-size: 12px;
        color: #999;
      }
      .ball-wrapper {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        width: auto;
        font-size: 14px;
        &.twentyBall {
          display: inline-grid;
          grid-template-columns: repeat(11, 1fr);
          grid-gap: 1px;
        }

        .ball:nth-child(10),.box:nth-child(10) {
          grid-column: span 2;
        }
        .ball {
          @include ballSize(0.22rem);
          margin-right: 2px;
          font-size: 0.12rem;
          grid-column: span 1;
          color: #fff;
        }
        .box {
          @include ballSize(0.22rem);
          margin-right: 2px;
          border-radius: 4px;
          font-size: 0.12rem;
          grid-column: span 1;
         
        }
       
       .type-detail{
          display:flex;
          width: 80%;
          
          span{
            display:table-cell;
            font-weight: bold;
            width:33%;
            margin: 0 8px;
            text-align: center;
          }
        }
        
      }
      .sum_result {
        span {
          font-weight: 600;
          width: 50%;
          display: inline-block;
        }
      }
    }
    td {
      padding: 8px 0;

      .btn {
        cursor: pointer;
        display: inline-block;
        color: #727272;
        margin: 0 12px;
        &.active {
            color: #fff;
            border-radius: 12px;
            background-color: #0f2957;
            padding: 6px 8px;
        }
      }
    }
  }
}
</style>
