<template>
    <div class="imagesLib" v-if="periodPredictSummary.Data != undefined">
        <div class="selectPeroid">
            <div class="wordList">
                <select v-model="nowTztkIndex" @change="changeTztkId">
                    <template v-for="(item, key) in tztkList.Data">
                        <option :key="key + '_tztk'" :value="item.Id">{{ item.PublicationName }}
                        </option>
                    </template>

                </select>
                <select v-model="selectPeroid" @change="changePeriodNo">
                    <template v-for="(item, key) in allPeriodNo.Data.Data">
                        <option :key="item.PeriodNo" :value="item.PeriodNo">{{ item.PeriodNo }}期</option>
                    </template>
                </select>
            </div>
            <template v-if="currentLotteryName == 'xglhc'">
                <div class="selectImgBtm">
                    <div class="btmStyle">
                        <div @click="changeImg('A')" class="btm">
                            <div class="btmSubStyle" :class="selectImgNumber == 'A' ? 'active' : ''">
                                <span>{{periodPredictSummary.Data.AName}}</span>
                            </div>
                        </div>
                        <div @click="changeImg('B')"  class="btm">
                            <div class="btmSubStyle" :class="selectImgNumber == 'B' ? 'active' : ''">
                                <span>{{periodPredictSummary.Data.BName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        
        <div class="tktzContent">
            <div class="showImagesLib" ref="saveimage">
                <img  class="tzimg" :src="imgSrc" ref="imgInfo" @load="handleLoad">
            </div>

            <!-- 英國六才有的說明 -->
            <div class="bottom" v-if="currentLotteryName == 'lucky7daily'||currentLotteryName == 'kl8lhc'">
                <!--本期-->
                <div class="result">
                    <div class="result_view">
                        <table>
                            <tr style="height:1%" class="shaxiao">
                                <td style="width:1%"><span>总彩波色：{{periodPredictSummary.Data.CurMystery.ZongCaiBoSe}}</span></td>
                                <td style="width:1%"><span>内幕杀肖：{{periodPredictSummary.Data.CurMystery.NeiMuShaXiao}}</span></td>
                                <td style="width:1%"><span>生肖玄机：{{periodPredictSummary.Data.CurMystery.ShengXiaoXuanJi}}</span></td>
                            </tr>
                            <tr style="height:1%">
                                <td colspan="3" style="text-align: center;">{{periodPredictSummary.Data.CurMystery.PoetryMystery}}</td>
                            </tr>
                            <tr style="height:1%" class="shaxiao">
                                <td colspan="3"><div>解： {{periodPredictSummary.Data.CurMystery.MysteryAnswer == null ? '更新中' : periodPredictSummary.Data.CurMystery.MysteryAnswer}}</div></td>
                            </tr>
                            <tr style="height:1%">
                                <td style="width:1%; text-align: center;">特码: {{periodPredictSummary.Data.CurMystery.SpecialDrawNo}}</td>
                                <td style="width:1%; text-align: center;">波: {{periodPredictSummary.Data.CurMystery.SpecialBo}}</td>
                                <td style="width:1%; text-align: center;">生肖: {{periodPredictSummary.Data.CurMystery.SpecialXiao}}</td>
                            </tr>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
        <div class="loading" :class="loading === true ? 'fadein':''">
            <div>载入中...</div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { getPredictPublication, getPredictPublicationPeriod, getPeriodPredictSummary } from "@/api/api.js"; //英國六
import { getLhcKBList, getLhcKBImgALL, getGetLhcKBImg } from "@/api/api.js"; //香港六

export default {
    components: {
    },
    props: {
        date: '',
    },
    data() {
        return {
            dataList: [],
            appendList: [],
            currentLotteryName: '',
            nowTztkIndex: 0,
            tztkList: [],
            publicationId: '',
            lotteryHistoryStatistics: '',
            firstPeriodNo: '',
            allPeriodNo: '',
            periodPredictSummary: '',
            selectList: '',
            selectPeroid: '',
            printHtml: '',
            imgSrc: '',
            selectImgNumber: 'A',
            isShow: false,
            loading:true,
        }
    },
    computed: {
      ...mapState([
        "lotteryInfo",   "lotteryDrawTime"
      ]),
    },
    watch: {
        '$route': {
            handler: function (to, from) {
              this.loading = true;
              if (this.currentLotteryName!==to.params.name) {
                this.init();
              }
              this.currentLotteryName = to.params.name;
            },
            immediate: true
        }
    },
    methods: {
        async init() {
            this.periodPredictSummary = {};
            if (this.currentLotteryName === 'lucky7daily') {
                this.tztkList = await getPredictPublication('1800');
                this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
                this.nowTztkIndex = this.publicationId;
                this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;
                //該期的圖紙資訊, 含珠子生肖
                this.periodPredictSummary = await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
                //圖紙位置

                this.imgSrc = this.periodPredictSummary.Data.Host.replace('https://w1','https://m0') + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else if (this.currentLotteryName === 'kl8lhc') {
              this.tztkList = await getPredictPublication('500');
              this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
              this.nowTztkIndex = this.publicationId;
              this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
              this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
              this.selectPeroid = this.firstPeriodNo;
              //該期的圖紙資訊, 含珠子生肖
              this.periodPredictSummary = await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
              //圖紙位置
              this.imgSrc = this.periodPredictSummary.Data.Host.replace('https://w1','https://m0') + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else if (this.currentLotteryName === 'xglhc') {
                //轉換香港六合彩變數名稱
                let tempInfo = await getLhcKBList();
                this.tztkList = {
                    Status: tempInfo.Status,
                    Data: []
                }
                tempInfo.Data.forEach(element => {
                    this.tztkList.Data.push({
                        Id: element.Id,
                        PublicationName: element.KBTypeName
                    })
                });
                this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
                this.nowTztkIndex = this.publicationId;

                tempInfo = await getLhcKBImgALL(this.publicationId)
                this.allPeriodNo = {
                    Status: tempInfo.Status,
                    Data: {
                        Data: []
                    }
                }
                for (let i = 0, j = tempInfo.Data.length > 10 ? 10 : tempInfo.Data.length; i < j; i++) {
                    this.allPeriodNo.Data.Data.push(tempInfo.Data[i])
                }
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;

                this.periodPredictSummary = await getGetLhcKBImg(this.publicationId, this.firstPeriodNo);
                this.imgSrc = this.periodPredictSummary.Data.AUrl.replace('https://w1','https://m0');

                this.selectImgNumber = 'A';

            }
        },
        //下拉選單選取旗號
        async changePeriodNo(){
            this.loading = true;
            if(this.currentLotteryName === 'lucky7daily'||this.currentLotteryName === 'kl8lhc'){
                this.periodPredictSummary =  await getPeriodPredictSummary(this.publicationId, this.selectPeroid);
                this.imgSrc = this.periodPredictSummary.Data.Host.replace('https://w1','https://m0') + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else {
                this.periodPredictSummary =  await getGetLhcKBImg(this.publicationId, this.selectPeroid);
                this.imgSrc = this.periodPredictSummary.Data.AUrl.replace('https://w1','https://m0');
                this.selectImgNumber = 'A';
            }
        },//changeList
        async changeTztkId() {
            this.loading = true;
            this.publicationId = this.nowTztkIndex;
            if(this.currentLotteryName === 'lucky7daily'||this.currentLotteryName === 'kl8lhc'){
                this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;
                //該期的圖紙資訊, 含珠子生肖
                this.periodPredictSummary =  await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
                if (this.periodPredictSummary.Data) {
                  this.imgSrc = this.periodPredictSummary.Data.Host.replace('https://w1','https://m0') + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
                }
            } else {
                let tempInfo = await getLhcKBImgALL(this.publicationId)
                this.allPeriodNo = {
                    Status : tempInfo.Status,
                    Data : {
                        Data: []
                    }
                }
                for(let i = 0, j = tempInfo.Data.length > 10 ? 10 : tempInfo.Data.length; i < j; i++){
                    this.allPeriodNo.Data.Data.push(tempInfo.Data[i])
                }
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;

                this.periodPredictSummary =  await getGetLhcKBImg(this.publicationId, this.firstPeriodNo);
                if (this.periodPredictSummary.Data) {
                  this.imgSrc = this.periodPredictSummary.Data.AUrl.replace('https://w1', 'https://m0');
                }
                this.selectImgNumber = 'A';
            }
        },
        
        changeImg(val){
            if(this.selectImgNumber === val){
                return false;
            } else {
                this.selectImgNumber = val;
                if(val === 'A'){
                    this.imgSrc = this.periodPredictSummary.Data.AUrl.replace('https://w1','https://m0');
                } else {
                    this.imgSrc = this.periodPredictSummary.Data.BUrl.replace('https://w1','https://m0');
                }
                this.loading = true;
            }
        },
        handleLoad(e){
            //圖片讀取完觸發
            this.loading = false;
       }
    },
    mounted() {
        this.currentHistoryName = this.$route.params.name;
    },
    created () {
        this.init();
    }
}
</script>
<style lang="scss" scoped>
    .selectPeroid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .wordList, .btmStyle{
            display: flex;
            justify-content:center;
            
            select{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                width: 50%;
                height: 42px;
                font-size: normal normal normal 14px/19px Segoe UI;
                border: 1px solid #eee;
                color: #726973;
                text-align: center;
                border-radius:0;
            }
            .selectImgBtm{
                margin-top: 2px;
            }
            .btm {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                width: 50%;
                height: 42px;
                font-size: 14px;
                text-align: center;

                .btmSubStyle{
                    width: 95%;
                    height: 32px;
                    margin-top: 6px;
                    margin-left: 5px;
                    border-radius: 16px;
                    border: solid 1px #59626c;
                    font-size: 14px;
                    line-height: 30px;
                    text-align: center;
                    color: #59626c;
                    cursor: pointer;
                    &.active{
                        border: 1px solid #0f2957;
                        border-radius: 12px;
                        background: #0f2957;
                        color: #fff;
                    }
                }
            }
        }
    }
    .tktzContent{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        .showImagesLib{
            .tzimg{
                width: 100%;
            }
        }
    }

    .showEnlargeImg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        background: #00000057 0% 0% no-repeat padding-box;

        img{
            width: 100%;
            max-height: 55%;
            position: absolute;
            left: 0px;
            top: 37%;
        }
    }

    .loading{
        border-radius:10px;
        padding:30px;
        position:fixed;
        top:45vh;
        left:50%;
        color:#fff;
        transform:translate(-50%, -50%);
        background:rgba(0,0,0,0.5);
        transition: opacity .5s;
        pointer-events: none;
        opacity:0;
    }
    .fadein{
    opacity:1;
    }


    .result{
        margin: 0 auto 0 auto;
        width: 95%;
        height: 205px;
        box-sizing: border-box;
        .result_view{
            height: 195px;
            table{
                height: 100%;                
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #D1D1D1;
                opacity: 1;
                font: normal normal normal 12px/16px Segoe UI;
                letter-spacing: 0.46px;
                color: #878787;
                opacity: 1;
                tr, td{
                    border: 1px solid #D1D1D1;
                    opacity: 1;
                }
                td{
                    text-align: left;
                }
                .shaxiao{
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                }
                span{
                    padding-left: 10px;
                    padding-right: 10px;
                    display:block;
                }
                div{
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
</style>
